import React from 'react'
import { Helmet } from "react-helmet"

const SEO = () => {
    return (
        <div>
            <Helmet>
                <title>Oba Coders - Provider of world class diversified IT Talent &amp; Solutions</title>
                <meta name="description" content="Oba Coders - A professional IT solutions and staffing firm specializing in providing contingent diversified workforce solutions for various technical endeavors." />
                <meta name="og:title" property="og:title" content="Oba Coders - A professional IT solutions and staffing firm specializing in providing contingent diversified workforce solutions for various technical endeavors."></meta>
                <meta name="twitter:card" content="Oba Coders - A professional IT solutions and staffing firm specializing in providing contingent diversified workforce solutions for various technical endeavors."></meta>
                <link rel="canonical" href="https://obacoders.com/"></link>
                <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/obacodersbrochurewebsite.appspot.com/o/obacoderswebsitepreview.png?alt=media&token=896193a1-41c1-43a4-93ab-902ec0554b53" />
            </Helmet>
        </div>
    )
}

export default SEO
